const wnd = window as any;

import * as jQuery from "jquery";
import { AudioProApi, StatsApi } from "../../generated/api";
import { formatPhoneNumber } from "../global/phone-number-formatter";

export function initChuDetail() {
  if (wnd.page === "chu-detail") {
    $(".js-rdv-url-link").on("click", function (event) {
      const form = $(this).parents("form");
      form.find("button[type=submit]").click();

      if (!(form[0] as HTMLFormElement).checkValidity()) {
        event.preventDefault();
      }
    });

    if (document.getElementById("map")) {
      var myLatLng = {
        lat: wnd.chu.location.coordinates[1],
        lng: wnd.chu.location.coordinates[0],
      };

      var map = new google.maps.Map(document.getElementById("map"), {
        zoom: 17,
        center: myLatLng,
      });

      var marker = new google.maps.Marker({
        position: myLatLng,
        map: map,
        title: wnd.chu.enseigne,
      });
      marker.setIcon({ url: "/assets/images/pin-blue.png" });
    }

    $(".rdv-button").on("click", function () {
      window["ga"]("send", {
        hitType: "event",
        eventCategory: "Fiche Centre",
        eventAction: "click",
        eventLabel: "Demande de RDV",
      });
      new StatsApi().saveStat({
        query: {
          centerId: window["chu"]._id,
          location: "detail",
          type: "click-rdv",
        },
      });
    });


    jQuery(".phone-button").on("click", async function (event) {
      console.log('clicked');
      var phoneNumber = $(this).data("phonenumber");
      var enseigne = $(this).data("enseigne");
      var city = $(this).data("city");
      var postalcode = $(this).data("postalcode");
      var address = $(this).data("address");

      var modalId =
        $(this).data("type") === "chu"
          ? "#chuPhoneNumberModal"
          : "#phoneNumberModal-" + $(this).data("audioproid");

      if ($(this).data("type") === "audiopro") {
        var id = $(this).data("audioproid");
        var phoneNumberResponse = await new AudioProApi().getPhoneNumber({
          audioProId: id,
        });
        phoneNumber = formatPhoneNumber(phoneNumberResponse.phoneNumber);
      }

      jQuery(modalId + " .modal-phone-number").text(phoneNumber);
      jQuery(modalId + " .modal-free-call").text(
        phoneNumber.substring(0, 3) === "0 8"
          ? "(Appel Gratuit)"
          : "(Appel non surtaxé)"
      );
      jQuery(modalId + " .modal-enseigne").text(enseigne);
      jQuery(modalId + " .modal-city").text(city);
      jQuery(modalId + " .modal-postalcode").text(postalcode);
      jQuery(modalId + " .modal-address").text(address);

      if ($(this).attr("href") === "#") {
        event.preventDefault();

        // Only on non mobiles devices
        if ($(document).width() > 576) {
          ($(modalId) as any).modal({
            backdrop: true,
          });
        } else {
          var $el = $(this).children(".telephone");
          if ($el.length) {
            if ($el.data("type") === "block") {
              $(this).replaceWith($el.show().css("display", "block"));
            } else {
              $(this).replaceWith($el.show().css("display", "inline-block"));
            }
          }
        }
      }
      window["ga"]("send", {
        hitType: "event",
        eventCategory: "CHU",
        eventAction: "click",
        eventLabel: "Telephone",
      });
    });

  }

  const scrollDiv = document.createElement("div");
  scrollDiv.className = "modal-scrollbar-measure";
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);

  // Compensate the navbar
  $("#phoneNumberModal").on("show.bs.modal", function (e) {
    $(".center-topbar").css("padding-right", scrollbarWidth);
  });

  $("#phoneNumberModal").on("hidden.bs.modal", function (e) {
    $(".center-topbar").css("padding-right", 0);
  });

  // Compensate the navbar
  $("#chuPhoneNumberModal").on("show.bs.modal", function (e) {
    $(".center-topbar").css("padding-right", scrollbarWidth);
  });

  $("#chuPhoneNumberModal").on("hidden.bs.modal", function (e) {
    $(".center-topbar").css("padding-right", 0);
  });
}
